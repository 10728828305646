
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import TextArea from "primevue/textarea";
import Divider from "primevue/divider";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MessageBox from "@/components/MessageBox.vue";
import AccountAutocomplete from "@/components/Autocompletes/AccountAutocomplete.vue";

import Utils from "@/utility/utils";
import { GeneralLedgerLineItem, GeneralLedgerEntry } from "@/types/state/generalLedger";
import store from "@/store";

export default defineComponent({
  name: "GLTransaction",
  components: {
    Card,
    Button,
    InputText,
    InputNumber,
    Calendar,
    TextArea,
    Divider,
    DataTable,
    Column,
    MessageBox,
    AccountAutocomplete,
  },
  data: () => ({
    showEmptyAccountsError: false,
    rowsWithErrors: [] as any[],
    saveErrorMessage: "",
    saveErrorMessageItems: [] as any[],
    showBalanceErrorDialog: false,
    currentId: "",
    transaction: {} as unknown as GeneralLedgerEntry,
    loadFrom: '',
    entryToDelete: {} as GeneralLedgerLineItem,
    showConfirmDeleteDialog: false,
    entryToUpdate: {
      amountType: '',
      data: {} as GeneralLedgerLineItem,
    } as any,
    showConfirmUpdateAmountDialog: false,
    targetElement: null as HTMLElement | null,
  }),
  computed: {
    ...mapGetters({
      getActiveOrderTab: "accountingInquiry/getActiveOrderTab",
      getFiscalYears: "accountingInquiry/getFiscalYears",
      getGlAccounts: "accountingInquiry/getGlAccounts",
    }),
    isReadOnly() {
      return this.$attrs.readOnly === true;
    },
    totalCredit() {
      return this.transaction.lineItems.reduce((acc: number, item: GeneralLedgerLineItem) => {
        return acc + parseFloat(item.credit);
      }, 0);
    },
    totalDebit() {
      return this.transaction.lineItems.reduce((acc: number, item: GeneralLedgerLineItem) => {
        return acc + parseFloat(item.debit);
      }, 0);
    },
    areQuantitiesMatching() {
      return this.totalCredit === this.totalDebit;
    },
    minYear() {
      const minYear = this.getFiscalYears.reduce((minYear: string, fyItem: any) => {
        const fiscalYear = fyItem.fiscal_year.replace('FY', '');
        return +minYear < +fiscalYear ? minYear : fiscalYear;
      }, '9999');
      return minYear;
    },
    maxYear() {
      const maxYear = this.getFiscalYears.reduce((maxYear: string, fyItem: any) => {
        const fiscalYear = fyItem.fiscal_year.replace('FY', '');
        return +maxYear > +fiscalYear ? maxYear : fiscalYear;
      }, '');
      return maxYear;
    },
    minDate() {
      if (!this.transaction.fiscalYear) {
        return new Date();
      }
      const periods = this.getPeriods(this.transaction.fiscalYear);
      return this.getMinDate(periods);
    },
    maxDate() {
      if (!this.transaction.fiscalYear ) {
        return new Date();
      }
      const periods = this.getPeriods(this.transaction.fiscalYear);
      return this.getMaxDate(periods);
    },
    minReverseDate() {
      if (!this.transaction.yearToReverse) {
        return new Date();
      }
      const periods = this.getPeriods(this.transaction.yearToReverse);
      return this.getMinDate(periods);
    },
    maxReverseDate() {
      if (!this.transaction.yearToReverse) {
        return new Date();
      }
      const periods = this.getPeriods(this.transaction.yearToReverse);
      return this.getMaxDate(periods);   
    },
  },
  async created() {
    await this.fetchFiscalYears()
    await this.fetchGlAccounts();
    await this.createdOrUpdated();
  },
  async updated() {
    await this.createdOrUpdated();
  },
  methods: {
    ...mapActions({
      addGLLineItem: "accountingInquiry/addGLLineItem",
      postGeneralLedgerTransaction: "accountingInquiry/postGlTransaction",
      fetchFiscalYears: "accountingInquiry/fetchFiscalYears",
      fetchGlTransactions: "accountingInquiry/fetchGlTransactions",
      fetchGlAccounts: "accountingInquiry/fetchGlAccounts",
    }),
    async createdOrUpdated() {
      if (!this.$attrs.transaction_id) {
        this.$router.push("/accounting/");
      }
      let id = this.$attrs.transaction_id as string;
      if (this.getActiveOrderTab(id) && this.getActiveOrderTab(id).record !== null) {
        this.transaction = this.getActiveOrderTab(id).record ? this.getActiveOrderTab(id).record as GeneralLedgerEntry  : { attachments_items: [] as any} as GeneralLedgerEntry;
      }
      this.currentId = id;
    },
    async handleSaveEntry() {
      await this.removeEmptyRows();
      await this.filterRowsWithErrors();

      for(let i = 0; i < this.transaction.lineItems.length; i++) {
        if (this.transaction.lineItems[i].account && 
          ((this.transaction.lineItems[i].debit === "0.00") && (this.transaction.lineItems[i].credit === "0.00"))){
            if (!this.isRowInError(this.transaction.lineItems[i].li)) {
                this.rowsWithErrors.push(this.transaction.lineItems[i].li);
            }
        }
        if(!this.transaction.lineItems[i].account) {
          if (!this.isRowInError(this.transaction.lineItems[i].li)) {
            this.rowsWithErrors.push(this.transaction.lineItems[i].li);
          }
        }
      }
      if (this.rowsWithErrors.length > 0) {
        this.saveErrorMessage =
          "The account number is required for all entries with Debit or Credit amount. Please correct the entries:";
        this.saveErrorMessageItems = this.rowsWithErrors.map((li: any) => {
          return {'Li number': li, 'Debit': this.formatPrice(this.transaction.lineItems.find((item: GeneralLedgerLineItem) => item.li === li)?.debit ?? '0'), 'Credit': this.formatPrice(this.transaction.lineItems.find((item: GeneralLedgerLineItem) => item.li === li)?.credit ?? '0')};
        });
        this.showBalanceErrorDialog = true;
        return;
      }

      if(this.transaction.lineItems.length === 0) {
        this.saveErrorMessage = "There are no entries to save. Please add entries to save the transaction.";
        this.saveErrorMessageItems = [];
        this.showBalanceErrorDialog = true;
        return;
      }
      if (this.totalCredit !== this.totalDebit) {
        this.saveErrorMessage =
          "Total Debit and Credit must be equal. Please correct the entries.";
        this.saveErrorMessageItems = [];
        this.showBalanceErrorDialog = true;
        return;
      }
      this.saveErrorMessage = "";

      // Save the transaction
      this.postGeneralLedgerTransaction(
        {
         newTransaction: this.transaction,
        }
      ).then((response) => {
        if (response.response.status === "failed") {
          const notification = {
            message: `Failed to save Order. ${response.response.error}`,
            type: "error",
          };
          store.dispatch("notification/add", notification);
        } else {
          this.currentId = response.response.recordId;
          this.transaction.transaction_id = this.currentId;
          this.getActiveOrderTab(this.currentId).old_record = this.transaction;
          const notification = {
            message: `Successfully Updated Order #${this.currentId}.`,
            type: "success",
          };
          store.dispatch("notification/add", notification);
          this.$router.push("/accounting/");
        }
      });
    },
    formatPrice(amt: string) {
      if (!amt || parseInt(amt) === 0) {
        return "$0.00";
      } else {
        return Utils.formatPrice(amt);
      }
    },
    formatDate(date: any, fullYear = false) {
      return Utils.formatDate(date, fullYear);
    },
    handleSelectedReverseYearChange(event: any) {
      let date = new Date(event);
      let month = new Date(this.transaction.date).getMonth();
      date.setMonth(month + 1);
      this.transaction.yearToReverse = date.getFullYear().toString();
      this.transaction.dateToReverse = this.formatDate(date, true);
    },
    handleSelectedDateToReverse(event: any) {
      this.transaction.dateToReverse = this.formatDate(event, true);
    },
    handleSelectedYearChange(event: any) {
      let date = new Date(event);
      let year = date.getFullYear();
      this.minDate = new Date(event);
      this.maxDate = new Date(year, 11, 31);
      this.transaction.fiscalYear = year.toString();
    },
    handleTransactionDate(event: any) {
      this.transaction.date = this.formatDate(event, true);
    },
    handleDeleteEntry(data: GeneralLedgerLineItem) {
      if (this.isRowEmpty(data)) {
        this.transaction.lineItems = this.transaction.lineItems.filter((item: GeneralLedgerLineItem) => item.li !== data.li);
        this.rowsWithErrors = this.rowsWithErrors.filter((li: any) => li !== data.li);
        return;
      }

      this.entryToDelete = data;
      this.showConfirmDeleteDialog = true;
    },
    isRowEmpty(data: any) {
      return (
        !data.account &&
        !data.accountDescription &&
        ((data.debit === "0.00") || (data.debit === 0)) &&
        ((data.credit === "0.00") || (data.credit === 0)) &&
        !data.notes
      );
    },
    async removeEmptyRows() {
      this.transaction.lineItems = await this.transaction.lineItems.filter((item: GeneralLedgerLineItem) => !this.isRowEmpty(item));
    },
    async filterRowsWithErrors() {
      this.rowsWithErrors = await this.rowsWithErrors.filter((li: any) => {
        const item = this.transaction.lineItems.find((i: GeneralLedgerLineItem) => i.li === li);
        return item ? true : false;
      });
    },
    deleteEntry() {
      this.showConfirmDeleteDialog = false;
      this.transaction.lineItems = this.transaction.lineItems.filter((item: GeneralLedgerLineItem) => item.li !== this.entryToDelete.li);
      this.rowsWithErrors = this.rowsWithErrors.filter((li: any) => li !== this.entryToDelete.li);
      this.entryToDelete = {} as GeneralLedgerLineItem;
      this.focusLastItem();
    },
    getMessageItemToDelete(item: GeneralLedgerLineItem) {
      return [{'Account': item.account, 'Description': item.accountDescription, 'Debit': this.formatPrice(item.debit), 'Credit': this.formatPrice(item.credit)}]
    },
    addNewLineItem() {
      let li: any = 1;
      if (this.transaction.lineItems && this.transaction.lineItems.length > 0) {
        li = this.transaction.lineItems[this.transaction.lineItems.length - 1].li;
        li = li + 1;
      }
      const item = {
        account: "",
        accountDescription: "",
        debit: "0.00",
        credit: "0.00",
        inactive: false,
        notes: "",
        li: li,
      } as unknown as GeneralLedgerLineItem;
      this.addGLLineItem({accountId: this.currentId, item: item});

      this.focusLastItem();
    },
    handleDebitChange(event: any, data: any, property: "credit" | "debit") {
      data[property] = event.value;
    },
    handleTabPressed(event: any, data: any) {
      if (this.isLastRow(data)) {
        this.addNewLineItem();
      }
    },
    isLastRow(data: any) {
      return data.li === this.transaction.lineItems[this.transaction.lineItems.length - 1].li;
    },
    focusLastItem() {
      setTimeout(() => {
        const element = document.querySelector(
          "#trial-balance-table .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr:last-child td:nth-child(2) input"
        ) as HTMLElement;
        if (element) {
          element.focus();
        }
      }, 0);
    },
    handleSelectAccount(account: any, data: any) {
      data.account = account.acct;
      data.accountDescription = account.desc;
    },
    handleExitDebitOrCredit(
      event: any,
      data: any,
      property: "credit" | "debit"
    ) {
      this.targetElement = event.originalEvent.target;

      const otherProperty = property === "debit" ? "credit" : "debit";
      if (data[property] > 0 && data[otherProperty] > 0) {
        this.showConfirmUpdateAmountDialog = true;
        this.entryToUpdate = {
          amountType: otherProperty,
          data: data,
        };
      }
    },
    updateAmount() {
      this.cleanConfirmUpdateAmountDialog(this.entryToUpdate.amountType);
    },
    handleCloseConfirmUpdateAmountDialog() {
      const amountType =
        this.entryToUpdate.amountType === "debit" ? "credit" : "debit";
      this.cleanConfirmUpdateAmountDialog(amountType);
    },
    cleanConfirmUpdateAmountDialog(property: "credit" | "debit") {
      this.showConfirmUpdateAmountDialog = false;
      this.entryToUpdate.data[property] = 0;
      this.entryToUpdate.data = {} as GeneralLedgerLineItem;
      this.entryToUpdate.amountType = '';
      setTimeout(() => {
        this.targetElement?.focus();
        this.targetElement = null;
      }, 0);
    },
    isRowInError(li: any) {
      return this.rowsWithErrors.includes(li);
    },
    loadRowsWithErrors() {
      this.rowsWithErrors = this.transaction.lineItems.filter((item: GeneralLedgerLineItem) => !item.account).map((item: GeneralLedgerLineItem) => item.li);
    },
    getPeriods(fiscalYear: string) {
      const year = "FY" + new Date(+fiscalYear, 1)
        .toLocaleDateString("en-US", {year: 'numeric'})
      return this.getFiscalYears.find((fy: any) => fy.fiscal_year === year)?.period_numbers_items;
    },
    getMinDate(periods: any) {
      const minDate = periods.reduce((minDate: string, period: any) => {
        const date = new Date(period.period_starts);
        return date < new Date(minDate) ? period.period_starts : minDate;
      }, periods[0].period_starts);
      return new Date(minDate);
    },
    getMaxDate(periods: any) {
      const maxDate = periods.reduce((maxDate: string, period: any) => {
        const date = new Date(period.period_ends);
        return date > new Date(maxDate) ? period.period_ends : maxDate;
      }, periods[0].period_ends);
      return new Date(maxDate);
    },
    handleOnBlur(event: any, data: any) {
      if (event.target.value === '') {
        data.account = '';
        data.accountDescription = '';
      }

      if(data.account){
        if(this.isRowInError(data.li)) {
          this.rowsWithErrors = this.rowsWithErrors.filter((li: any) => li !== data.li);
        }
      }
    },
    handleLoadFrom() {
      if (this.loadFrom) {
        this.fetchGlTransactions({id: this.loadFrom}).then((response) => {
            this.transaction.lineItems = [];
            response.gltrans_items[0].lines_items.forEach((item: any) => {
              item.li = this.transaction.lineItems.length + 1;
              item.account = item.accts;
              item.accountDescription = this.getGlAccounts.find((account: any) => account.acct === item.accts)?.desc;
              if (item.amounts > 0) {
                item.debit = item.amounts;
                item.credit = "0.00";
              } else {
                item.credit = ""+Math.abs(item.amounts);
                item.debit = "0.00";
              }
              this.transaction.lineItems.push(item);
            });
        });
      }
    }
  },
});
