
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";

export default defineComponent({
  name: "MessageBox",
  components: {
    Dialog,
    Button,
    InputText,
    Dropdown,
    Calendar
  },
  data() {
    return {
      visible: true,
      input: "",
    };
  },
  mounted() {
    this.input = this.inputValue || "";
  },
  computed: {
    isInputDropdown() {
      return this.inputType?.toUpperCase() === "DROPDOWN";
    },
    isInputDate() {
      return this.inputType?.toUpperCase() === "DATE";
    },
    isInputText() {
      return this.inputType?.toUpperCase() === "TEXT";
    },
    isInputNumber() {
      return this.inputType?.toUpperCase() === "NUMBER";
    },
    iconClass() {
      let iconClass = "";
      switch (this.icon) {
        case "QUESTION":
          iconClass = "pi pi-question-circle mr-3";
          break;
        case "INFO":
          iconClass = "pi pi-exclamation-triangle mr-3";
          break;
        case "WARNING":
          iconClass = "pi pi-exclamation-triangle mr-3";
          break;
        case "DESKTOP":
          iconClass = "pi pi-desktop mr-3";
          break;
        case "PRINT":
          iconClass = "pi pi-print mr-3";
          break;
        default:
      }
      return iconClass;
    },
    messageLines() {
      return this.message?.includes("\r\n")
        ? this.message?.split("\r\n")
        : null;
    },
    processedMessageLines() {
      return this.messageLines && this.messageLines.filter(msg => msg.includes("has been"));
    }
  },
  methods: {
    primaryButtonClick() {
      if(this.inputType) {
        this.$emit("primaryButtonClick", this.input);
      } else {
        this.$emit("primaryButtonClick");
      }
    },
    cancelButtonClick() {
      this.$emit("cancelButtonClick");
    },
  },
  props: {
    showDialog: Boolean,
    header: String,
    icon: String,
    message: String,
    messageItems: Array,
    primaryButton: String,
    cancelButton: String,
    options: Object,
    optionLabel: String,
    optionValue: String,
    inputValue: String,
    inputType: String,
    inputLabel: String,
  },
});
